<style lang="scss" scoped>
.table-header {
  border: 1px solid #ebeef5;
  border-bottom: none;
  height: 50px;
}
.refresh {
  background-color: #f5f5f5;
  width: 50px;
  &:hover .icon_refresh {
    color: rgba(0, 0, 0, 0.6);
  }
}
.icon_refresh {
  color: #999;
  line-height: 50px;
}
.operation {
  white-space: nowrap;
}

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
<template>
  <div class="account height_100 flex_tb">
    <!-- 條件刪選 -->
    <collapse @seach="state.seach" />
    <div class="width_100 table-header sizing flex_lr_m">
      <div>
        <i class="el-icon-s-grid pad20" style="color: #999"></i>
        <el-button type="primary" size="mini" @click="addOpen">新增</el-button>
        <el-button
          type="danger"
          size="mini"
          @click="del_accout"
          :disabled="state.delAccountId.length === 0"
          >批量刪除</el-button
        >
      </div>
      <div class="refresh sizing height_100 flex_c_m" @click="refresh">
        <i class="el-icon-refresh cursor fonts-22 icon_refresh"></i>
      </div>
    </div>
    <!-- 表格 -->
    <el-table
      :data="state.tableData"
      height="100%"
      border
      style="width: 100%"
      class="flex1"
      :row-class-name="tableRowClassName"
      @selection-change="selection_change"
    >
      <el-table-column type="selection" width="50"> </el-table-column>
      <el-table-column prop="id" label="優惠券id" min-width="70">
      </el-table-column>
      <el-table-column prop="title" label="優惠券名稱" min-width="70">
      </el-table-column>
      <el-table-column prop="type" label="類別" min-width="50">
        <template #default="scope">
          <span>{{
            scope.row.type == 1
              ? "立減"
              : scope.row.type == 2
              ? "满减"
              : scope.row.type == 3
              ? "立折"
              : "满折"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="stock" label="庫存" min-width="50">
      </el-table-column>
      <el-table-column prop="price" label="兌換價格" min-width="50">
      </el-table-column>
      <el-table-column prop="discount" label="折扣" min-width="50">
      </el-table-column>
      <el-table-column prop="status" label="狀態" min-width="50">
        <template #default="scope">
          <span>{{ scope.row.status ? "正常" : "禁用" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="explain" label="使用説明" min-width="50">
      </el-table-column>
      <el-table-column
        prop="member_name"
        label="操作"
        width="150px"
        fixed="right"
      >
        <template #default="scope">
          <div class="operation height_100 flex_c_m">
            <el-button type="primary" size="mini" @click="editInfo(scope.row)"
              >編輯</el-button
            >
            <el-button type="danger" size="mini" @click="deleteInfo(scope.row)"
              >刪除</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="flex_m_r pain pad20">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="state.user.page"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="Number(state.count)"
      >
      </el-pagination>
    </div>
    <!-- 新增彈框 -->
    <el-dialog
      title="新增優惠券"
      v-model="state.dialogFormVisible"
      width="600px"
    >
      <el-form
        size="small"
        :model="state.ruleForm"
        :rules="state.rules"
        label-width="120px"
        class="demo-ruleForm"
        :ref="(el) => (state.addFrom = el)"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="優惠券名稱" prop="title">
              <el-input v-model="state.ruleForm.title" class="wd200"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="兌換所需積分" prop="price">
              <el-input-number
                v-model="state.ruleForm.price"
                class="wd200"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="兌換類型" prop="type">
              <el-select
                v-model="state.ruleForm.type"
                placeholder="請選擇優惠券類型"
              >
                <el-option
                  v-for="(e, i) in state.typeList"
                  :key="i"
                  :label="e.label"
                  :value="e.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            :span="24"
            v-if="state.ruleForm.type == 2 || state.ruleForm.type == 4"
          >
            <el-form-item label="滿減/滿折額度" prop="lowest">
              <el-input-number
                v-model="state.ruleForm.lowest"
                class="wd200"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="優惠折扣" prop="discount">
              <el-input
                v-model="state.ruleForm.discount"
                placeholder="立/滿折：0.9為9折，立/滿減：折扣金額"
                class="wd200"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="兌換次數" prop="convert">
              <el-input-number
                v-model="state.ruleForm.convert"
                class="wd200"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="庫存" prop="stock">
              <el-input-number
                v-model="state.ruleForm.stock"
                class="wd200"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="狀態" prop="status">
              <el-select
                v-model="state.ruleForm.status"
                placeholder="請選擇優惠券狀態"
              >
                <el-option
                  v-for="(e, i) in state.startList"
                  :key="i"
                  :label="e.label"
                  :value="e.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" class="flex">
            <el-form-item label="活動時間" prop="start_time">
              <el-date-picker
                v-model="state.time"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="開始日期"
                end-placeholder="結束日期"
                @change="timeChange"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="使用說明" prop="explain">
              <el-input
                type="textarea"
                v-model="state.ruleForm.explain"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="state.dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="add_submit">確 定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog title="提示" v-model="state.dialog" width="400px">
      <span>確定刪除嗎？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="state.dialog = false">取 消</el-button>
          <el-button type="primary" @click="dels_confirm">確 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import Collapse from "./components/collapse";

export default {
  components: {
    Collapse,
  },
  setup() {
    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $http, $message, $utils } = proxy;
    const store = useStore();

    const req_table_data = () => {
      $http.getCouponList(state.user).then((res) => {
        if (res.status !== 200) return;
        state.tableData = res.data.data;
        state.count = res.data.count;
      });
    };

    // 表单验证
    const validatePass = (rule, value, callback) => {
      // 密碼正則校驗
      if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/.test(value)) {
        callback(new Error("密碼至少包含數字和英文,6-18個字符"));
      } else {
        callback();
      }
    };

    const validateEmail = (rule, value, callback) => {
      // 郵箱正則校驗
      if (
        !/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
          value
        )
      ) {
        callback(new Error("請輸入正確的電郵"));
      } else {
        callback();
      }
    };

    const validatePhone = (rule, value, callback) => {
      // 手機號碼正則校驗
      if (
        !(
          /^([5|6|8|9|2|3])\d{7}$/.test(value) ||
          /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57]|19[0-9])[0-9]{8}$/.test(
            value
          )
        )
      ) {
        callback(new Error("請輸入正確的號碼"));
      } else {
        callback();
      }
    };

    const lowestRule = (rule, value, callback) => {
      // 優惠折扣
      if (!/^(\d|\.)+$/.test(value)) {
        callback(new Error("請輸入正確的折扣"));
      } else {
        callback();
      }
    };

    const state = reactive({
      tableData: [],
      user: {
        limit: 10,
        page: 1,
        is_end: "",
        type: "",
        status: "",
        title: "",
      },
      time: [],
      addFrom: "",
      ruleForm: {
        id: "",
        title: "",
        lowest: 0,
        type: 1,
        price: 0,
        discount: "",
        stock: 0,
        convert: 0,
        start_time: "",
        end_time: "",
        explain: "",
        status: 1,
      },
      rules: {
        //表单验证
        title: [
          { required: true, message: "請輸入優惠券名稱", trigger: "blur" },
        ],
        price: [
          { required: true, message: "請輸入兌換所需積分", trigger: "blur" },
        ],
        type: [
          { required: true, message: "請選擇優惠券類型", trigger: "blur" },
        ],
        lowest: [
          { required: true, message: "請輸入滿減/滿折額度", trigger: "blur" },
        ],
        discount: [
          { required: true, message: "請輸入優惠折扣", trigger: "blur" },
          {
            validator: lowestRule,
            trigger: "blur",
          },
        ],
        convert: [
          { required: true, message: "請輸入兌換次數", trigger: "blur" },
        ],
        stock: [
          { required: true, message: "請輸入優惠劵庫存", trigger: "blur" },
        ],
        start_time: [
          { required: true, message: "請選擇活動時間", trigger: "change" },
        ],
        end_time: [
          { required: true, message: "請選擇結束時間", trigger: "blur" },
        ],
        explain: [
          { required: true, message: "請輸入使用說明", trigger: "blur" },
        ],
        status: [
          { required: true, message: "請選擇分類狀態", trigger: "blur" },
        ],
      },
      typeList: [
        { label: "立减", value: 1 },
        { label: "满减", value: 2 },
        { label: "立折", value: 3 },
        { label: "满折", value: 4 },
      ],
      startList: [
        { label: "正常", value: 1 },
        { label: "禁用", value: 0 },
      ],
      count: "", // 總條數
      dialogFormVisible: false, // 新增彈出層
      formLabelWidth: "70px",
      checkedKeys: [],
      delAccountId: [],
      delUserId: [],
      dialog: false,
      userInfo: {},
      show_pass: true,
      currId: "",
      form: {
        name: "",
        phone: "",
        email: "",
      },
      seach(data) {
        state.user.title = data.seatch_name;
        state.user.status = data.region;
        state.user.type = data.type;
        req_table_data();
      },
      se_checkedKeys(data) {
        state.checkedKeys = data;
      },
    });
    // 生命周期
    /* created */

    req_table_data();

    // methods
    const handleCurrentChange = (val) => {
      state.user.page = val;
      req_table_data();
    };

    const handleSizeChange = (val) => {
      state.user.limit = val;
      req_table_data();
    };

    const refresh = () => {
      req_table_data();
    };

    const add_submit = () => {
      state.addFrom.validate(async (valid) => {
        if (!valid) return;
        const res = await $http.handleCoupon(
          Object.assign(state.ruleForm, {
            admin_id: $utils.getCookie("adminId"),
          })
        );
        if (res.status !== 200) return;
        $message.success("操作成功");
        req_table_data();
        state.dialogFormVisible = false;
      });
    };

    const selection_change = (selection) => {
      state.delAccountId = selection.map((item) => {
        item = item.id;
        return item;
      });
    };

    const del_accout = () => {
      state.delUserId = [];
      state.dialog = true;
    };

    const dels_confirm = async () => {
      const res = await $http.delCoupon({
        id: state.delUserId.length === 0 ? state.delAccountId : state.delUserId,
      });
      if (res.status !== 200) return;
      req_table_data();
      $message.success("刪除成功");
      state.dialog = false;
    };

    const editInfo = async (data) => {
      state.ruleForm = data;
      state.ruleForm.id = data.id;
      state.ruleForm = {
        id: data.id,
        title: data.title,
        lowest: data.lowest,
        type: data.type,
        price: data.price,
        discount: data.discount,
        stock: data.stock,
        convert: data.convert,
        start_time: data.start_time,
        end_time: data.end_time,
        explain: data.explain,
        status: data.status,
      };
      state.time = [state.ruleForm.start_time, state.ruleForm.end_time];
      state.admin_id = "";
      state.dialogFormVisible = true;
    };

    const addOpen = () => {
      state.form.name = "";
      state.form.email = "";
      state.form.phone = "";
      state.form.region = "";
      state.show_pass = true;
      state.userInfo = [];
      state.dialogFormVisible = true;
      state.time = [];
      state.ruleForm = {
        id: "",
        title: "",
        lowest: 0,
        type: 1,
        price: 0,
        discount: "",
        stock: 0,
        convert: 0,
        start_time: "",
        end_time: "",
        explain: "",
        status: 1,
      };
    };

    // 刪除管理員
    const deleteInfo = (data) => {
      state.dialog = true;
      state.delUserId = [data.id];
    };

    const timeChange = (data) => {
      console.log(data, state.time);
      if (data) {
        state.ruleForm.start_time = data[0];
        state.ruleForm.end_time = data[1];
      }
    };

    const tableRowClassName = ({ row, rowIndex }) => {
      if (row.stock <= 1) {
        return "warning-row";
      }
      return "";
    };

    return {
      state,
      handleCurrentChange,
      handleSizeChange,
      refresh,
      add_submit,
      selection_change,
      del_accout,
      dels_confirm,
      editInfo,
      addOpen,
      deleteInfo,
      timeChange,
      tableRowClassName,
    };
  },
};
</script>
